<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- 搜索、导入按钮 -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  class="mr-1"
                  style="margin: 15px;"
                  @click="advanced_search_excel"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">导入</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- 导入 -->
        <b-card v-show="advanced_search_modal_excel">

          <b-row>
            <!--excel-->
            <b-col md="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="相关附件"
                  label-for="attachments_ids"
                  label-size="sm"
                  class="mb-1"
              >

                <attachment-upload :theme="'files'"
                                   :object_type="'excel'"
                                   @change="onUploaded"
                />
              </b-form-group>
            </b-col>

            <!--导入关闭-->
            <b-col
                cols="12"
                md="12"
                class="demo-inline-spacing d-flex align-items-center justify-content-end"
            >
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="exportData"
              >
                <span class="align-right">导入</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search_excel"
              >
                <span class="align-right">关闭</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>

        <!-- 高级搜索 -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition">
            <b-row>
              <!--调出仓库-->
              <b-col md="3">
                <modal-select
                    label="调出仓库"
                    type="input"
                    v-on:change="fromChildren($event,['warehouse_name_from','warehouse_id_from'])"
                    :params="['warehouse_name_from','warehouse_id_from']"
                    modalName="仓库"
                    placeholder="点击选择仓库"
                    v-model="condition.warehouse_name_from"
                >
                </modal-select>
              </b-col>
              <!--调入仓库-->
              <b-col md="3">
                <modal-select
                    label="调入仓库"
                    type="input"
                    v-on:change="fromChildren($event,['warehouse_name_to','warehouse_id_to'])"
                    :params="['warehouse_name_to','warehouse_id_to']"
                    modalName="仓库"
                    placeholder="点击选择仓库"
                    v-model="condition.warehouse_name_to"
                >
                </modal-select>
              </b-col>
              <!--承运商-->
              <b-col md="3">
                <modal-select
                    label="承运商"
                    type="input"
                    v-on:change="fromChildren($event,['company_name','company_id'])"
                    :params="['company_name','company_id']"
                    modalName="承运商"
                    placeholder="点击选择承运商"
                    v-model="condition.company_name"
                >
                </modal-select>
              </b-col>
              <!--默认计价-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="默认计价方式"
                    label-for="default_rule"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="default_rule"
                      :options="getCodeOptions('car_detail').slice(11,14)"
                      v-model="condition.default_rule"
                      class="select-size-sm"
                      :reduce="option => option.value"
                      placeholder="请选择计价方式"
                  />
                </b-form-group>
              </b-col>
              <!--添加人-->
              <b-col md="3">
                <modal-select
                    label="创建人"
                    type="input"
                    v-on:change="fromChildren($event,['full_name','user_id'])"
                    :params="['full_name','user_id']"
                    modalName="创建人"
                    placeholder="点击选择创建人"
                    v-model="condition.full_name"
                >
                </modal-select>
              </b-col>
              <!--查询、重置按钮-->
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="tran_id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(tran_id)="data">
          #{{ data.item.id }}
        </template>

        <!--商品分类-->
        <template #cell(category_tow_id)="data">
          {{ getCodeLabel('category', data.item.category_tow_id) }}
        </template>

        <!-- default_rule -->
        <template #cell(default_rule)="data">
            {{ getCodeLabel('car_detail', data.item.default_rule) }}
        </template>

        <!-- status -->
        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('fee_transport_setting_status', data.item.status)}`"
          >
            {{ getCodeLabel('fee_transport_setting_status', data.item.status) }}
          </b-badge>
        </template>

        <!-- 备注 -->
        <template #cell(remark)="data">
          <div :id="`memo-${data.item.id}`">
            <feather-icon icon="Edit3Icon"/>
          </div>
          <b-tooltip
              :target="`memo-${data.item.id}`"
              placement="top"
          >
            {{ data.item.remark }}
          </b-tooltip>
        </template>

        <!-- 附件 -->
        <template #cell(attachments)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :id="data.item.attachments"
                             :readonly="true"
          />
        </template>

        <!-- 添加时间       -->
        <template #cell(create_time)="data">
          {{ toTime(data.item.create_time) }}
        </template>

        <!--添加人        -->
        <template #cell(creator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!-- 更新时间       -->
        <template #cell(update_time)="data">
          {{ toTime(data.item.update_time) }}
        </template>

        <!--更新人        -->
        <template #cell(updator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >
            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'apps-feetransportsetting-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeState(data.item)">
              <feather-icon icon="XIcon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <!--导入错误信息显示-->
      <b-modal
          id="exportErrorModalId"
          centered
          hide-footer
          size="lg"
          ref="exportErrorModalId"
          title="导入错误信息"
      >
        <b-row>
          <b-col cols="12">
            <b-form-textarea
                id="textarea-default"
                rows="10"
                :value="exportErrorMsg"
            />
          </b-col>
        </b-row>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, toRefs, onMounted, reactive} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import feetransportsettingUseList from './feetransportsettingUseList'
import feetransportsettingStore from './feetransportsettingStore'
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import Ripple from "vue-ripple-directive";
import ModalSelect from "@/views/components/modal/ModalSelect";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalSelect,
    AttachmentUpload,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isShowCard: false,
      advanced_search_modal_excel: false,
      exportErrorMsg:'',
    }
  },
  setup() {
    // Use toast
    const toast = useToast()
    // Register module
    if (!store.hasModule('feetransportsetting')) store.registerModule('feetransportsetting', feetransportsettingStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('feetransportsetting')) store.unregisterModule('feetransportsetting')
    })

    const changeState = function (data) {
      this.$swal({
        title: '确定删除吗',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('feetransportsetting/state', {id: data.id, state: 0}).then(res => {
            if (res.data.code == 0) {
              //弹窗
              this.$swal({
                icon: 'success',
                title: '已删除!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
        }
      })
    }

    const advanced_search = function () {
      this.isShowCard = !this.isShowCard
    }

    const advanced_search_excel = function () {
      this.advanced_search_modal_excel = !this.advanced_search_modal_excel;
    }

    const onUploaded = function (id, attachment, result) {
      this.excelSqlId = result
    }

    const exportData = function (){
      let that = this
      const params = {
        excelSqlId: this.excelSqlId,
      }
      store.dispatch('feetransportsetting/importExcel', params)
          .then(response => {
            console.log(response)
            const data = response.data.data
            if (response.data.code==0){
              toast.success("导入成功")
              refetchData();
            }else {
              console.log("错误信息")
              console.log(data)
              that.$refs['exportErrorModalId'].show()
              // this.exportErrorModal = true
              that.exportErrorMsg = data
            }
          })
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      this.condition={}
      refetchData()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // UI
    } = feetransportsettingUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      changeState,
      advanced_search,
      advanced_search_excel,
      onUploaded,
      exportData,
      fromChildren,
      searchByCondition,
      resetCondition,
      condition,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
