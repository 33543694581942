import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function feetransportsettingUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)
  const condition = ref({})

  // Table Handlers
  const tableColumns = [
    { key: 'tran_id', label: 'ID', sortable: true },
    { key: 'warehouse_name_from', label: '调出仓库名称', sortable: true },
    { key: 'warehouse_name_to', label: '调入仓库名称', sortable: true },
    { key: 'company_name', label: '承运商名称', sortable: true },
    { key: 'category_tow_id', label: '商品分类', sortable: true },
    { key: 'default_rule', label: '默认计价方式', sortable: true },
    { key: 'rule0', label: '依维柯', sortable: true },
    { key: 'rule1', label: '三轮车', sortable: true },
    { key: 'rule2', label: '面包车', sortable: true },
    { key: 'rule3', label: '4.2m', sortable: true },
    { key: 'rule4', label: '5.2m', sortable: true },
    { key: 'rule5', label: '6.8m', sortable: true },
    { key: 'rule6', label: '7.6m', sortable: true },
    { key: 'rule10', label: '8.7m', sortable: true },
    { key: 'rule7', label: '9.6m', sortable: true },
    { key: 'rule8', label: '13m', sortable: true },
    { key: 'rule9', label: '17.5m', sortable: true },
    { key: 'rule11', label: '按件', sortable: true },
    { key: 'rule12', label: '其他', sortable: true },
    { key: 'status', label: '状态', sortable: true },
    //{ key: 'state', label: '启用', sortable: true },
    { key: 'remark', label: '备注', sortable: true },
    { key: 'attachments', label: '素材包', sortable: true },
    { key: 'create_time', label: '添加时间', sortable: true },
    { key: 'creator', label: '添加人', sortable: true },
    { key: 'update_time', label: '更新时间', sortable: true },
    { key: 'updator', label: '更新人', sortable: true },
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('tran_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('feetransportsetting/search', {
        warehouse_id_from : condition.value.warehouse_id_from,
        warehouse_id_to : condition.value.warehouse_id_to,
        company_name : condition.value.company_name,
        default_rule : condition.value.default_rule,
        creator : condition.value.user_id,
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    condition,

    // Extra Filters
  }
}
